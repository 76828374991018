import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useAppContext } from '../../../../../components/Main/AppContext';
import { getGuidanceTableData } from '../../../../../components/Company/CompanyEvents/Analysis/AnalysisDetail/CompanyEventMetricListFunctions';
import './MetricGuidanceTable.css';

function MetricGuidanceTable() {
    const { companyMetricDetails, setCompanyMetricValueId } = useAppContext();

    const columns = [
        { field: 'publishDate', headerName: 'Publish Date', flex: 1 },
        { field: 'publishEvent', headerName: 'Publish Event', flex: 1 },
        { field: 'guidancePeriod', headerName: 'Guidance Period', flex: 1 },
        { field: 'guidanceValue', headerName: 'Guidance Value', flex: 1 },
    ];

    const rows = companyMetricDetails && companyMetricDetails.length > 0
        ? getGuidanceTableData(companyMetricDetails[0])
        : [];

    const handleRowClick = (params) => {
        const selectedMetric = companyMetricDetails[0].metrics.find(metric => metric.company_metric_value_id === params.row.id);
        if (selectedMetric) {
            setCompanyMetricValueId(selectedMetric.company_metric_value_id);
        }
    };

    return (
        <Box className="metric-guidance-boundary-box">
            <DataGrid
                className="search-data-grid"
                rows={rows}
                columns={columns}
                hideFooter
                autoHeight
                disableColumnMenu
                rowHeight={30}
                getRowId={(row) => row.id}
                onRowClick={handleRowClick}
            />
        </Box>
    );
}

export default MetricGuidanceTable;
