import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material'; 
import "./SourceDocumentTranscript.css";
import TranscriptionBlocks from '../../../../Transcripts/Detail/TranscriptionBlocks.js';
import { useAppContext } from '../../../../Main/AppContext';

// Delay in milliseconds before applying highlighting
const HIGHLIGHT_DELAY = 1000;

function SourceDocumentTranscript() {
    const detailPaneRef = useRef(null);
    const { 
        keTranscriptId, 
        utteranceNumList,
        companyMetricValueId,
        companyMetricDetails,
        setUtteranceNumList
    } = useAppContext();
    const [localUtteranceNumList, setLocalUtteranceNumList] = useState([]);

    useEffect(() => {
        if (companyMetricValueId && companyMetricDetails && companyMetricDetails.length > 0) {
            const selectedMetric = companyMetricDetails[0].metrics.find(
                metric => metric.company_metric_value_id === companyMetricValueId
            );
            if (selectedMetric && selectedMetric.source_location_text_segment_id) {
                setUtteranceNumList([selectedMetric.source_location_text_segment_id]);
            }
        }
    }, [companyMetricValueId, companyMetricDetails, setUtteranceNumList]);

    useEffect(() => {
        // Immediately set to empty array to clear any existing highlights
        setLocalUtteranceNumList([]);

        // Set a timer to apply the new highlights after a delay
        const timer = setTimeout(() => {
            setLocalUtteranceNumList(utteranceNumList);
        }, HIGHLIGHT_DELAY);

        // Cleanup function to clear the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer);
    }, [utteranceNumList, keTranscriptId]);

    useEffect(() => {
        if (localUtteranceNumList.length > 0 && detailPaneRef.current) {
            const highlightedElement = detailPaneRef.current.querySelector('.highlighted');
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [localUtteranceNumList]);

    return (
        <Box ref={detailPaneRef} className="source-document-transcript-pane" style={{ height: '100%', overflow: 'auto' }}>
            <TranscriptionBlocks
                key={keTranscriptId}
                ke_transcript_id={keTranscriptId}
                utterance_num_list={localUtteranceNumList}
            />
        </Box>
    );
}

export default SourceDocumentTranscript;
