import React from 'react';
import { Box } from '@mui/material';
import MetricJSONDetail from './MetricJSONDetail';
import "./MetricJSON.css";

function MetricJSON() {
    return (
        <Box className="metric-json-boundary-box">
            <MetricJSONDetail />
        </Box>
    );
}

export default MetricJSON;