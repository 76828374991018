import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(null);
  const [companyEventId, setCompanyEventId] = useState(null);
  const [companyMetricId, setCompanyMetricId] = useState(null);
  const [companyMetrics, setCompanyMetrics] = useState([]);
  const [companyMetricDetails, setCompanyMetricDetails] = useState(null);
  const [keTranscriptId, setKeTranscriptId] = useState(null);
  const [eventTitle, setEventTitle] = useState('');
  const [eventType, setEventType] = useState('');
  const [companyEventSourceDocList, setCompanyEventSourceDocList] = useState([]);
  const [sourceDocId, setSourceDocId] = useState(null);
  const [utteranceNumList, setUtteranceNumList] = useState([]);
  const [companyMetricValueId, setCompanyMetricValueId] = useState(null);

  return (
    <AppContext.Provider
      value={{
        companyId,
        setCompanyId,
        companyEventId,
        setCompanyEventId,
        companyMetricId,
        setCompanyMetricId,
        companyMetrics,
        setCompanyMetrics,
        companyMetricDetails,
        setCompanyMetricDetails,
        keTranscriptId,
        setKeTranscriptId,
        eventTitle,
        setEventTitle,
        eventType,
        setEventType,
        companyEventSourceDocList,
        setCompanyEventSourceDocList,
        sourceDocId,
        setSourceDocId,
        utteranceNumList,
        setUtteranceNumList,
        companyMetricValueId,
        setCompanyMetricValueId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);