import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, Typography, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscripts } from "../../graphql_ap/queries";
import { logUserEvent } from '../User/UserFunctions';
import './TranscriptsSidebar.css';

const drawerWidth = 390; // 400

function extractDateFromUTC(dateString) {
    const date = new Date(dateString);
    return `${
      date.getUTCMonth() + 1
    }-${date.getUTCDate()}-${date.getUTCFullYear()}`;
  }
  
  function extractDateForSortFromUTC(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;  // JavaScript months are 0-based
    const day = date.getUTCDate();
  
    // Ensure month and day are two digits by padding with a leading zero if necessary.
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    return `${year}${formattedMonth}${formattedDay}`;
  }
  
  function extractTimeFromUTC(dateString) {
    const date = new Date(dateString);
    const options = {
      timeZone: "America/New_York",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleTimeString("en-US", options);
  }

const TranscriptsSidebar = ({onTranscriptSelect}) => {
    const theme = useTheme();
    const [open, setOpen] = useState(true); // sidebar is open by default
    const [transcripts, setTranscripts] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
          try {
            const transcriptData = await API.graphql(
              graphqlOperation(listDynKeTranscripts)
            );
            const transcriptList = transcriptData.data.listDynKeTranscripts;
            setTranscripts(
              transcriptList.map((transcript) => ({
                ...transcript,
                id: transcript.ke_transcript_id,
              }))
            );
          } catch (error) {
            console.error("Error fetching transcripts:", error);
          }
        };
    
        fetchData();
      }, []);

    const handleDrawerToggle = () => {
    setOpen(!open);
    };

    const handleDrawerOpen = () => { // used to be able to open the sidebar by clicking anywhere
    if (!open) setOpen(true);
    };

    const columns = [
        { field: "ticker", headerName: "Ticker", width: 70 },
        {
          field: "date",
          headerName: "Date",
          width: 100,
          valueGetter: (params) => ({
            formattedValue: extractDateFromUTC(params.row.call_utc),
            sortValue: extractDateForSortFromUTC(params.row.call_utc) 
          }),
          sortComparator: (v1, v2, param1, param2) => param1.api.getCellValue(param1.id, 'date').sortValue - param2.api.getCellValue(param2.id, 'date').sortValue,
          renderCell: (params) => params.value.formattedValue
        },
        {
          field: "time",
          headerName: "Time (ET)",
          width: 90,
          valueGetter: (params) => extractTimeFromUTC(params.row.call_utc),
        },
        { field: "call_title", headerName: "Event", width: 80 },
      ];
    
      const rowClickHandler = (params) => {
        let ke_transcript_id = params.id;
        let call_title = params.row.call_title;
        let ticker = params.row.ticker;
        let ai_summary = params.row.ai_summary;
        onTranscriptSelect(ke_transcript_id, call_title, ticker,ai_summary);
        logUserEvent('transcript-select', ticker+": "+call_title);
      };
    
      const handleFilterChange = (event) => {
        setFilter(event.target.value);
      };
    
      const filteredTranscripts = transcripts.filter((transcript) =>
        transcript.ticker.toLowerCase().includes(filter.toLowerCase())
      );


    return (
        <Drawer
          variant="permanent"
          open={open}
          onClick={handleDrawerOpen} // top open the sidebar by clicking anywhere
          sx={{
            width: open ? drawerWidth : theme.spacing(7) + 1,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: open ? drawerWidth : theme.spacing(7) + 1,
              boxSizing: 'border-box',
              height: 'calc(100vh - 62px - 24px - 2px)',
              overflow: 'hidden',
              top: 'inherit',
              position: 'relative',
              zIndex: 'auto',
              ...(open ? {} : {
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }),
            },
          }}
        >
          {open && (
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left', fontWeight: 'bold' , paddingLeft: '10px'}}>
                Transcripts
              </Typography>
              <IconButton onClick={handleDrawerToggle}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Box>
          )}
          <Divider />
          {!open && (
            <IconButton onClick={handleDrawerToggle} sx={{ width: '100%' }}>
              <MenuIcon />
            </IconButton>
          )}
          {open && <TextField
            id="outlined-search"
            label="ticker"
            type="search"
            size="small"
            autoFocus // Automatically focus this field
            value={filter}
            onChange={handleFilterChange}
            style={{ margin: '10px', marginTop: '20px', mx: 2, maxWidth: '200px' }}
          />}
          {open && 
               <DataGrid
               className="searchDataGrid"
               rows={filteredTranscripts}
               columns={columns}
               hideFooter
               rowHeight={30}
               onRowClick={rowClickHandler}
               style={{marginLeft: '10px', marginRight: '10px'}}
               sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
             />   
          }
        </Drawer>
      );
    };

export default TranscriptsSidebar;