import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from 'aws-amplify';
import { useTheme } from '@mui/material';
import AppBarLogoSVG from "../../assets/images/app-bar-logo.svg";
import { checkUserPages, checkUserSettings, getUserFirstAndLastName, logUserEvent} from "../User/UserFunctions";
import Search from "../Search/Search";
import { useAppContext } from './AppContext';
import { useContext } from 'react';
import UserContext from '../User/UserContext';

function ResponsiveAppBar(props) {
  const { setCompanyId } = useAppContext();
  const { hasAcceptedTerms } = useContext(UserContext);
  const [pages, setPages] = useState([]);
  const [settings, setSettings] = useState([]);
  const [userFullName, setUserFullName] = useState('');
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [canAccessSearch, setCanAccessSearch] = useState(false);
  const navigate = useNavigate(); 
  const theme = useTheme();

  const hoverStyle = {
    "&:hover": {
      color: (theme) => theme.palette.ke.link_hover, 
    },
  };

  const hoverStyleAvatar = {
    "&:hover": {
      color: (theme) => theme.palette.ke.dark_blue, 
      background: (theme) => theme.palette.ke.light_blue, 
    },
  };

  const activeAvatarStyle = {
    color: (theme) => theme.palette.ke.dark_blue, 
    background: (theme) => theme.palette.ke.light_blue,
  };

  useEffect(() => {
    if (props.user) {
      const fetchData = async () => {
        try {
          const userPages = await checkUserPages();
          setPages(userPages);

          const userSettings = await checkUserSettings();
          setSettings(userSettings);

          const fullName = await getUserFirstAndLastName();
          setUserFullName(fullName);

          // Check if user can access Search
          const canAccess = userPages.includes('Transcripts') && hasAcceptedTerms;
          setCanAccessSearch(canAccess);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchData();
    }
  }, [props.user, hasAcceptedTerms]);

  const handleOpenNavMenu = (event) => {
    logUserEvent('nav-menu','open')
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    logUserEvent('user-menu','open')
    setAnchorElUser(event.currentTarget);
    setIsUserMenuOpen(true);
  };

  const handleCloseNavMenu = () => {
    logUserEvent('nav-menu','close')
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    logUserEvent('user-menu','close')
    setAnchorElUser(null);
    setIsUserMenuOpen(false);
  };

  const handleMenuClick = (page) => {
    logUserEvent('click-menu-page',`${page.toLowerCase()}`)
    navigate(`/${page.toLowerCase()}`);
    handleCloseNavMenu();
  };

  const handleUserMenuClick = (setting) => {
    if (setting === 'Logout') {
      logUserEvent('user-menu','logout')
      Auth.signOut();
      navigate('/');
    } else {
      navigate(`/${setting.toLowerCase()}`);
    }
    handleCloseUserMenu();
  };

  const handleLogoClick = (e) => {
    e.preventDefault(); 
    logUserEvent('click-menu-page','LOGO')
    navigate('/welcome'); 
  };

  const signInButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue,
    '&:hover': {
      backgroundColor: theme.palette.ke.link_hover
    }
  };

  return (
    <AppBar position="static" sx={(theme) => ({ background: theme.palette.ke.dark_blue , height: 62})}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            cursor: 'pointer',
          }}
          onClick={handleLogoClick}
        >
          <img
            src={AppBarLogoSVG}
            alt="AppBar Logo"
            width="35"
            height="35"
          />
        </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={(e) => {
              e.preventDefault(); 
              logUserEvent('click-menu-page','ALPHA-PILOT.AI')
              navigate('/welcome'); 
            }}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace", 
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "white", 
              textDecoration: "none",
              paddingLeft: "4px",
              ...hoverStyle,
            }}
          >
            ALPHA-PILOT.AI
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {props.user && (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ ...hoverStyle }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={() => handleMenuClick(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>

          {canAccessSearch && <Search setCompanyId={setCompanyId} />}
          <div style={{ width: '20px' }}></div>

          <div id="old logo placeholder"></div>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#"
            onClick={(e) => {
              e.preventDefault(); 
              logUserEvent('click-menu-page','ALPHA-PILOT')
              navigate('/welcome'); 
            }}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              ...hoverStyle,
            }}
          >
            ALPHA-PILOT
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {props.user && pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleMenuClick(page)}
                sx={{ my: 2, color: "white", display: "block", ...hoverStyle }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {!props.user && (
              <Button variant="contained" sx={signInButtonStyle}  onClick={() => navigate('/signin')}>
              Sign In
            </Button>
            )}
            {props.user && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0}}>
                  <Avatar 
                    alt={userFullName} 
                    src="/static/images/avatar/2.jpg" 
                    sx={{...(isUserMenuOpen ? activeAvatarStyle : hoverStyleAvatar)}}
                    />
                </IconButton>
              </Tooltip>
            )}
            {props.user && (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleUserMenuClick(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
