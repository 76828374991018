// Helper function to format number with commas and optional decimals
const formatNumber = (num) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// Helper function to get the scale factor
const getScaleFactor = (scale) => {
  const scaleFactors = {
    one: 1,
    ten: 10,
    hundred: 100,
    thousand: 1000,
    million: 1000000,
    billion: 1000000000,
    trillion: 1000000000000
  };
  return scaleFactors[scale] || 1;
};

// Helper function to get currency symbol
const getCurrencySymbol = (currency) => {
  const currencySymbols = {
    'USD': '$', 'EUR': '€', 'GBP': '£', 'CAD': 'C$', 'AUD': 'A$', 'CNY': '¥', 'JPY': '¥',
    'RUB': '₽', 'INR': '₹', 'BRL': 'R$', 'MXN': 'Mex$', 'KRW': '₩', 'IDR': 'Rp', 'TRY': '₺',
    'ZAR': 'R', 'NGN': '₦', 'AED': 'د.إ', 'SAR': '﷼', 'QAR': 'ر.ق', 'KWD': 'د.ك', 'OMR': 'ر.ع.',
    'BHD': '.د.ب', 'IQD': 'ع.د', 'LYD': 'ل.د', 'EGP': 'E£', 'SYP': 'S£', 'YER': '﷼', 'JOD': 'د.ا',
    'ILS': '₪', 'LBP': 'L£', 'PKR': '₨', 'AFN': '؋', 'IRR': '﷼', 'KZT': '₸', 'UZS': 'лв',
    'TJS': 'ЅМ', 'TMT': 'T', 'AZN': '₼', 'GEL': '₾', 'AMD': '֏', 'BYN': 'Br', 'UAH': '₴',
    'MDL': 'L', 'RSD': 'дин.', 'HRK': 'kn', 'BAM': 'KM', 'MKD': 'ден', 'BGN': 'лв', 'RON': 'lei',
    'HUF': 'Ft', 'CZK': 'Kč', 'PLN': 'zł', 'SEK': 'kr', 'NOK': 'kr', 'DKK': 'kr', 'ISK': 'kr',
    'CHF': 'Fr.', 'NZD': 'NZ$'
  };
  return currencySymbols[currency] || currency;
};

// Helper function to get last two digits of the year
const getLastTwoDigits = (year) => {
  if (typeof year === 'number') {
    return year.toString().slice(-2);
  } else if (typeof year === 'string') {
    return year.slice(-2);
  }
  return year; // Return as is if it's neither a number nor a string
};

// Format guidance string for CompanyEventMetricList
export const formatGuidanceString = (metric) => {
  if (!metric || (!metric.guidance && (!metric.metrics || metric.metrics.length === 0))) return null;

  const formatSingleGuidance = (item) => {
    const prefix = `${item.metric_date_standardized_date_prefix}'${getLastTwoDigits(item.metric_date_standardized_fiscal_year)}: `;
    const currencySymbol = metric.is_monetary ? getCurrencySymbol(metric.currency) : '';
    
    let valueString;
    if (item.value_range) {
      const [low, high] = item.value_range.map(v => formatNumber(v));
      valueString = `${currencySymbol}${low} to ${currencySymbol}${high}`;
    } else {
      valueString = `${currencySymbol}${formatNumber(item.value)}`;
    }

    if (item.scale && item.scale !== 'one') {
      valueString += ` ${item.scale}`;
    }

    if (!metric.is_monetary && metric.non_monetary_units) {
      valueString += ` ${metric.non_monetary_units}`;
    }

    return `${prefix}${valueString}`;
  };

  const guidanceItems = metric.guidance || metric.metrics.filter(item => item.status === 'estimate' && item.is_included);
  return guidanceItems.map(formatSingleGuidance).join(', ');
};

// Format guidance period for MetricGuidanceTable
export const formatGuidancePeriod = (item) => {
  return `${item.metric_date_standardized_date_prefix}'${getLastTwoDigits(item.metric_date_standardized_fiscal_year)}`;
};

// Format guidance value for MetricGuidanceTable
export const formatGuidanceValue = (item, metric) => {
  const currencySymbol = metric.is_monetary ? getCurrencySymbol(metric.currency) : '';
  
  let valueString;
  if (item.value_range) {
    const [low, high] = item.value_range.map(v => formatNumber(v));
    valueString = `${currencySymbol}${low} to ${currencySymbol}${high}`;
  } else {
    valueString = `${currencySymbol}${formatNumber(item.value)}`;
  }

  if (item.scale && item.scale !== 'one') {
    valueString += ` ${item.scale}`;
  }

  if (!metric.is_monetary && metric.non_monetary_units) {
    valueString += ` ${metric.non_monetary_units}`;
  }

  return valueString;
};

// Format publish date for MetricGuidanceTable
export const formatPublishDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
};

// Get guidance data for MetricGuidanceTable
export const getGuidanceTableData = (metric) => {
  if (!metric || !metric.metrics || metric.metrics.length === 0) return [];

  return metric.metrics
    .filter(item => item.status === 'estimate' && item.is_included)
    .map(item => ({
      id: item.company_metric_value_id,
      publishDate: formatPublishDate(item.event_utc),
      publishEvent: item.event_title,
      guidancePeriod: formatGuidancePeriod(item),
      guidanceValue: formatGuidanceValue(item, metric)
    }));
};

// Get actuals data for MetricActualsTable
export const getActualsTableData = (metric) => {
  if (!metric || !metric.metrics || metric.metrics.length === 0) return [];

  return metric.metrics
    .filter(item => item.status === 'actual' && item.is_included)
    .map(item => ({
      id: item.company_metric_value_id,
      publishDate: formatPublishDate(item.event_utc),
      publishEvent: item.event_title,
      actualPeriod: formatGuidancePeriod(item), // We can reuse this function for actuals
      actualValue: formatGuidanceValue(item, metric) // We can reuse this function for actuals
    }));
};
