import React from 'react';
import { Box } from '@mui/material';
import MetricActualsTable from './MetricActualsTable';
import "./MetricActuals.css";

function MetricActuals() {
    return (
        <Box className="metric-actuals-boundary-box">
            <MetricActualsTable />
        </Box>
    );
}

export default MetricActuals;