import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import MetricGraph from "./MetricGraph.js"
import MetricGuidanceTable from "./MetricGuidanceTable.js"
import MetricActuals from "./MetricActuals.js"
import MetricJSON from "./MetricJSON.js"
import { useAppContext } from "../../../../Main/AppContext";
import { API } from 'aws-amplify';
import { listCompanyMetricsWithDetails } from '../../../../../graphql_ap/queries';
import "./Metric.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`metric-tabpanel-${index}`}
      aria-labelledby={`metric-tab-${index}`}
      className="metric-tab-panel"
      {...other}
      sx={{ p: 1, height: 'calc(100% - 8px)', width: 'calc(100% - 8px)' }} // Subtract padding from both height and width
    >
      {value === index && (
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function Metric() {
    const [tabValue, setTabValue] = useState(0);
    const { companyMetricId, companyEventId, companyMetricDetails, setCompanyMetricDetails } = useAppContext();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (companyMetricId && companyEventId) {
            const fetchMetricDetails = async () => {
                try {
                    const response = await API.graphql({
                        query: listCompanyMetricsWithDetails,
                        variables: { 
                            company_metric_id: companyMetricId, 
                            selected_company_event_id: companyEventId 
                        }
                    });
                    const fetchedDetails = response.data.listCompanyMetricsWithDetails;
                    setCompanyMetricDetails(fetchedDetails);
                    setError(null);
                } catch (error) {
                    console.error("Error fetching metric details:", error);
                    setError("Failed to fetch metric details. Please try again.");
                }
            };
            fetchMetricDetails();
        }
    }, [companyMetricId, companyEventId, setCompanyMetricDetails]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    
    return (
        <Box className="metric-container" sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Typography variant="subtitle2" component="h2" className="metric-title">
                {Array.isArray(companyMetricDetails) && companyMetricDetails.length > 0
                    ? 'Metric: '+ companyMetricDetails[0].company_metric_name
                    : ''}
            </Typography>
            <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="metric tabs" 
                className="metric-tabs"
                centered
                sx={{ minHeight: '40px', maxHeight: '40px' }} // Smaller height for tabs
                >
                <Tab label="Graph" id="metric-tab-0" aria-controls="metric-tabpanel-0" />
                <Tab label="Actuals" id="metric-tab-1" aria-controls="metric-tabpanel-1" />
                <Tab label="Guidance" id="metric-tab-2" aria-controls="metric-tabpanel-2" />
                <Tab label="JSON" id="metric-tab-3" aria-controls="metric-tabpanel-3" />
            </Tabs>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', width: '100%' }}>
                <TabPanel value={tabValue} index={0}>
                    <MetricGraph />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <MetricActuals />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>  
                    <MetricGuidanceTable />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <MetricJSON />
                </TabPanel>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
        </Box>
    );
}

export default Metric;
