import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import CompanyHeader from './CompanyHeader/CompanyHeader';
import CompanySummary from './CompanySummary/CompanySummary';
import CompanyEvents from './CompanyEvents/CompanyEvents';
import "./Company.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={{ p: 2 }}  // Optional padding style
    >
      {value === index && (
        <Typography component="div">{children}</Typography> 
      )}
    </Box>
  );
}

function Company() {
  const location = useLocation();
  const { company_id, company_name, primary_exchange, primary_ticker } = location.state || {};
  console.log(company_name)
  const [tabValue, setTabValue] = useState(1);  // use 1 for "EVENTS" tab as default or 0 for "SUMMARY" tab as default

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className='company-main-container' sx={(theme) => ({ 
      background: theme.palette.ke.background,
      })}>
      <CompanyHeader 
              companyName={company_name} 
              primaryTicker={primary_ticker} 
              primaryExchange={primary_exchange} 
      />
      
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        aria-label="company tabs" 
        centered
        sx={{
          minHeight: '35px',
          maxHeight: '35px',
          '& .MuiTab-root': {
            minHeight: '35px',
            padding: '5px 5px 5px 5px',
          },
        }}
      >
        <Tab label="Summary" id="tab-0" aria-controls="tabpanel-0" />
        <Tab label="Events" id="tab-1" aria-controls="tabpanel-1" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <CompanySummary />
      </TabPanel>
      
      <TabPanel value={tabValue} index={1}>
        <CompanyEvents />
      </TabPanel>
    </Box>
  );
};

export default Company;
