import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import './MetricGraph.css';
import { useAppContext } from '../../../../Main/AppContext';

Chart.register(...registerables);

const MetricGraph = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  let resizeTimeout = useRef(null);
  const { companyMetricId } = useAppContext();

  useEffect(() => {
    console.log(`Fetching data for metric ID: ${companyMetricId}`);
    // In a real implementation, you would fetch data based on companyMetricId here

    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: ["3Q'22", "4Q'22", "1Q'23", "2Q'23", "3Q'23", "4Q'23", "1Q'24", "2Q'24", "3Q'24", "4Q'24"],
      datasets: [
        {
          label: 'Actuals',
          data: [66, 65, 57, 64, 72, 103, 99, 110, null, null],
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          order: 1,
          type: 'bar',
        },
        {
          label: 'Guidance Low',
          data: [null, null, null, null, null, null, 116.25, 116.25, 116.25, 116.25],
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: '+1',
          type: 'line',
          pointRadius: 0,
          order: 0,
        },
        {
          label: 'Guidance High',
          data: [null, null, null, null, null, null, 135, 135, 135, 135],
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: false,
          type: 'line',
          pointRadius: 0,
          order: 0,
        },
        {
          label: 'Guidance Midpoint',
          data: [null, null, null, null, null, null, 125.625, 125.625, 125.625, 125.625],
          borderColor: 'rgba(54, 162, 235, 1)',
          borderDash: [5, 5],
          type: 'line',
          pointRadius: 0,
          order: 2,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          // title: {
          //   display: true,
          //   text: `Copper Production (Metric ID: ${companyMetricId})`,
          // },
        },
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
            beginAtZero: true,
          },
        },
      },
    };

    // Create chart instance
    chartInstanceRef.current = new Chart(ctx, config);

    // Add resize observer to resize the chart dynamically
    const resizeObserver = new ResizeObserver(() => {
      // Throttle the resize to avoid triggering it too frequently
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
      resizeTimeout.current = setTimeout(() => {
        chartInstanceRef.current.resize();
      }, 200); // Adjust the delay as needed
    });

    resizeObserver.observe(chartRef.current);

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
      chartInstanceRef.current.destroy();
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [companyMetricId]);

  return (
    <Box className='metric-boundary-box' sx={{ width: '100%', height: '100%' }}>
      <canvas ref={chartRef}></canvas>
    </Box>
  );
};

export default MetricGraph;
