import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { StarBorder, Star, Search } from '@mui/icons-material';
import "./CompanyEventMetricList.css";
import { useAppContext } from '../../../../../components/Main/AppContext';
import { API } from 'aws-amplify';
import { listCompanyMetricsForSelectedEvent } from '../../../../../graphql_ap/queries';
import { formatGuidanceString } from './CompanyEventMetricListFunctions';

const CompanyEventMetricList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { 
    setCompanyMetricId, 
    companyId, 
    companyEventId,
    companyMetrics,
    setCompanyMetrics
  } = useAppContext();

  const fetchMetrics = useCallback(async () => {
    if (companyId && companyEventId) {
      setLoading(true);
      setError(null);
      try {
        const response = await API.graphql({
          query: listCompanyMetricsForSelectedEvent,
          variables: { company_id: companyId, selected_company_event_id: companyEventId }
        });
        const fetchedMetrics = response.data.listCompanyMetricsForSelectedEvent;
        console.log('Fetched metrics:', fetchedMetrics);
        if (fetchedMetrics && Array.isArray(fetchedMetrics)) {
          setCompanyMetrics(fetchedMetrics);
          if (fetchedMetrics.length > 0 && !companyMetrics.length) {
            setCompanyMetricId(fetchedMetrics[0].company_metric_id);
          }
        } else {
          setCompanyMetrics([]);
        }
      } catch (err) {
        console.error("Error fetching metrics:", err);
        setError("No metrics available.");
        setCompanyMetrics([]);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId, companyEventId, setCompanyMetrics, setCompanyMetricId, companyMetrics.length]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleFavoriteToggle = useCallback((id) => {
    // TODO: Implement the mutation to update the favorite status on the server
    console.log('Toggle favorite for metric:', id);
    setCompanyMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.company_metric_id === id
          ? { ...metric, is_favorite: !metric.is_favorite }
          : metric
      )
    );
  }, [setCompanyMetrics]);

  const handleRowClick = (params) => {
    console.log('Selected metric ID:', params.row.company_metric_id);
    setCompanyMetricId(params.row.company_metric_id);
  };

  const sortedAndFilteredMetrics = useMemo(() => {
    const filtered = companyMetrics.filter((metric) =>
      metric.company_metric_name.toLowerCase().includes(searchQuery)
    );

    return filtered.sort((a, b) => {
      // First, sort by is_favorite
      if (a.is_favorite !== b.is_favorite) {
        return a.is_favorite ? -1 : 1;
      }

      // If not favorites, sort by presence of guidance
      const aHasGuidance = (a.guidance && a.guidance.length > 0) || (a.metrics && a.metrics.some(m => m.status === 'estimate' && m.is_included));
      const bHasGuidance = (b.guidance && b.guidance.length > 0) || (b.metrics && b.metrics.some(m => m.status === 'estimate' && m.is_included));
      if (aHasGuidance !== bHasGuidance) {
        return aHasGuidance ? -1 : 1;
      }

      // If both have or don't have guidance, sort alphabetically by metric name
      return a.company_metric_name.localeCompare(b.company_metric_name);
    });
  }, [companyMetrics, searchQuery]);

  const columns = [
    {
      field: 'is_favorite',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleFavoriteToggle(params.row.company_metric_id);
          }}
          aria-label="favorite"
        >
          {params.row.is_favorite ? <Star color="primary" /> : <StarBorder color="primary" />}
        </IconButton>
      ),
    },
    { field: 'company_metric_name', headerName: 'Metric', flex: 1.6 },
    { 
      field: 'guidance', 
      headerName: 'Guidance', 
      flex: 1,
      valueGetter: (params) => formatGuidanceString(params.row) || '-'
    },
  ];

  return (
    <Box className='event-metric-list-boundary-box'>
      <Box sx={{ padding: '10px' }}>
        <TextField
          id="outlined-search"
          placeholder="Search metrics..."
          type="search"
          value={searchQuery}
          onChange={handleSearchChange}
          size="small"
          sx={{ 
            marginBottom: '10px', 
            width: '300px',
            '& .MuiInputBase-root': {
              height: '32px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <DataGrid
          className="search-data-grid"
          style={{ height: 'calc(100vh - 62px - 24px - 2px - 60px - 238px)' }} 
          rows={loading ? [] : sortedAndFilteredMetrics}
          columns={columns}
          hideFooter
          rowHeight={30}
          onRowClick={handleRowClick}
          getRowId={(row) => row.company_metric_id}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          sortingMode="server"
          sortModel={[]}
          loading={loading}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography>
                  {error ? error : loading ? 'Loading metrics...' : 'No available metrics.'}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default CompanyEventMetricList;
