import React, { useState } from 'react';
import { Box, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAppContext } from "../../../../Main/AppContext";
import './MetricJSONDetail.css';

function MetricJSON() {
    const { companyMetricDetails } = useAppContext();
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCopy = () => {
        if (companyMetricDetails) {
            navigator.clipboard.writeText(JSON.stringify(companyMetricDetails, null, 2))
                .then(() => setOpenSnackbar(true))
                .catch(err => console.error('Failed to copy text: ', err));
        }
    };

    return (
        <Box className="metric-json-container">
            <IconButton 
                onClick={handleCopy} 
                size="small" 
                aria-label="copy to clipboard" 
                className="copy-button"
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
            <pre className="metric-json-content">
                {companyMetricDetails ? JSON.stringify(companyMetricDetails, null, 2) : 'Loading...'}
            </pre>
            {/* <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            /> */}
        </Box>
    );
}

export default MetricJSON;