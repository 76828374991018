import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Button, useTheme, FormControlLabel, Checkbox, Typography } from '@mui/material';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from 'react-router-dom';
import UserContext from "../User/UserContext";
import CardActions from "@mui/material/CardActions";
import { logUserNDA, checkUserNDA } from '../User/UserFunctions';
import "./Terms.css";
import "./NDA.css";

function NDA({ redirectTo }) {
  const theme = useTheme();
  const { setNeedsToSignNDA } = useContext(UserContext);
  const navigate = useNavigate();
  const [hasSignedNDA, setHasSignedNDA] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    discussionAgreement: false,
    screenshotAgreement: false
  });

  useEffect(() => {
    const checkNDAStatus = async () => {
      const ndaStatus = await checkUserNDA();
      setHasSignedNDA(!ndaStatus); // If ndaStatus is true, user needs to sign (hasn't signed)
    };
    checkNDAStatus();
  }, []);

  const acceptNDAButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue
    }
  };

  const handleCheckboxChange = (name) => (event) => {
    setCheckboxes({
      ...checkboxes,
      [name]: event.target.checked
    });
  };

  const isAcceptEnabled = checkboxes.discussionAgreement && checkboxes.screenshotAgreement;

  const clickAcceptNDAHandler = async () => {
    try {
      await logUserNDA();
      setNeedsToSignNDA(false);
      navigate(redirectTo);
    } catch (error) {
      console.error('Error logging NDA agreement');
    }
  };

  return (
    <Box className="mainBox" sx={(theme) => ({ background: theme.palette.ke.background })}>
      <Card className="termsCard nda-card">
        <CardHeader 
          title="Early Access Program" 
          sx={(theme) => ({ 
            background: theme.palette.ke.card_header, 
            textAlign: 'center', 
            padding: '15px'
          })} 
        />
        <CardContent className="nda-content">
          <Typography variant="body2" paragraph>
            You have been selected for optional participation in the Alpha-Pilot.ai early access program.
          </Typography>
          <Typography variant="body2" paragraph>
            This program lets you view and try early prototypes and features before they become available to Alpha-Pilot's general customer base.
          </Typography>
          <Typography variant="body2" paragraph>
            In order to participate in the program, we ask that you agree to a Non-Disclosure Agreemement (NDA) to prevent premature diclosure of Alpha-Pilot's new plans and features to our competition.
          </Typography>
          <Typography variant="body2" paragraph>
            Please agree to the NDA with the following key terms:
          </Typography>
          
          <FormControlLabel
            className="nda-checkbox-label"
            control={
              <Checkbox
                checked={checkboxes.discussionAgreement}
                onChange={handleCheckboxChange('discussionAgreement')}
              />
            }
            label={
              <Typography variant="body2">
                I will not discuss or show new Alpha-Pilot.ai features with individuals outside my organization or those inside my organization not bound by an NDA.
              </Typography>
            }
          />
          
          <FormControlLabel
            className="nda-checkbox-label"
            control={
              <Checkbox
                checked={checkboxes.screenshotAgreement}
                onChange={handleCheckboxChange('screenshotAgreement')}
              />
            }
            label={
              <Typography variant="body2">
                I will not take screenshots or recordings of Alpha-Pilot.ai features or demonstrations.
              </Typography>
            }
          />

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, mb: 1 }}>
            Confidentiality Period
          </Typography>
          <Typography variant="body2" paragraph>
            I understand that this NDA remains in effect until the specific features or prototypes disclosed to me become publicly available or are no longer under development.
          </Typography>

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, mb: 1 }}>
            Consequences of Breach
          </Typography>
          <Typography variant="body2" paragraph>
            I understand that failure to comply with this NDA may result in legal action and/or termination of my access to Alpha-Pilot.ai early access features.
          </Typography>

          <Typography variant="body2" paragraph>
            User also agrees to "Terms of Use" available at https://alpha-pilot.ai/terms.
          </Typography>

          <Typography variant="body2" paragraph>
            Access to the program is subject to the sole discretion of Alpha-Pilot.ai.
          </Typography>
        </CardContent>
        {!hasSignedNDA && (
          <CardActions className="nda-actions">
            <Button 
              variant="contained" 
              sx={acceptNDAButtonStyle} 
              onClick={clickAcceptNDAHandler}
              disabled={!isAcceptEnabled}
            >
              Accept
            </Button>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}

export default NDA;
