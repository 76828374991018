import React, { useState } from 'react';
import { Box } from '@mui/material'; 
import GuidanceTable from '../../../../Transcripts/Summary/GuidanceTable';
import PreparedRemarksBlocks from '../../../../Transcripts/Summary/PreparedRemarksBlocks';
import QuestionAnswerBlocks from '../../../../Transcripts/Summary/QuestionAnswerBlocks';
import { useAppContext } from '../../../../Main/AppContext';
import "./CompanyTranscriptSummaryPane.css";

const CompanyTranscriptSummaryPane = (props) => {
  const { setUtteranceNumList } = useAppContext();
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleUtteranceNumListSelect = (utteranceNumList) => {
    setUtteranceNumList(utteranceNumList);
    if (props.onUtteranceSelect) {
      props.onUtteranceSelect(utteranceNumList);
    }
  };

  const handleSelectItem = (itemId) => {
    setSelectedItemId(itemId);
    if (props.onSelectItem) {
      props.onSelectItem(itemId);
    }
  };

  return (
    <Box className="company-transcript-summary-pane">
        <GuidanceTable 
          selectedItemId={selectedItemId} 
          onSelectItem={handleSelectItem}
          ke_transcript_id={props.ke_transcript_id} 
          onUtteranceNumListSelect={handleUtteranceNumListSelect}
        />
        <PreparedRemarksBlocks 
          selectedItemId={selectedItemId} 
          onSelectItem={handleSelectItem}
          ke_transcript_id={props.ke_transcript_id} 
          onUtteranceNumListSelect={handleUtteranceNumListSelect}
        />
        <QuestionAnswerBlocks 
          selectedItemId={selectedItemId} 
          onSelectItem={handleSelectItem}
          ke_transcript_id={props.ke_transcript_id} 
          onUtteranceNumListSelect={handleUtteranceNumListSelect}
        />
    </Box>
  );
};

export default CompanyTranscriptSummaryPane;