import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptTranscriptionBlocks } from "../../../graphql_ap/queries";
import './TranscriptionBlocks.css'
import SingleTranscriptionBlock from './SingleTranscriptionBlock';

const TranscriptionBlocks = ({ ke_transcript_id, utterance_num_list, onTranscriptLoad }) => {
    const [transcriptionBlocks, setTranscriptionBlocks] = useState([]);

    useEffect(() => {
        async function fetchData() {
            if (ke_transcript_id) {
                try {
                    const transcriptionBlockData = await API.graphql(
                        graphqlOperation(listDynKeTranscriptTranscriptionBlocks, {
                            ke_transcript_id: ke_transcript_id,
                        })
                    );
                    const blocks = transcriptionBlockData.data.listDynKeTranscriptTranscriptionBlocks || [];
                    setTranscriptionBlocks(blocks.map(item => ({
                        ...item,
                        id: item.transcription_block_num
                    })));
                    if (onTranscriptLoad) {
                        onTranscriptLoad();
                    }
                } catch (error) {
                    console.error("Error fetching transcription blocks:", error);
                }
            }
        }

        fetchData();
        
    }, [ke_transcript_id, onTranscriptLoad]);

    return (
        <div className='transcription-blocks-main'>
            {transcriptionBlocks && transcriptionBlocks.length > 0 ? (
                transcriptionBlocks.map((transcriptionBlock) => (
                    <div key={transcriptionBlock.id}>
                        <b>{transcriptionBlock.speaker_name}, {transcriptionBlock.speaker_title}:</b>
                        <SingleTranscriptionBlock 
                            transcription_block_id={transcriptionBlock.transcription_block_id} 
                            utterance_num_list={utterance_num_list}
                        />
                    </div>
                ))
            ) : (
                <div></div>
                /* No transcription blocks found */
            )}
        </div>
    );
};

export default TranscriptionBlocks;
