import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import CompanyEventMetricList from "./CompanyEventMetricList";
import CompanyTranscriptSummaryPane from './CompanyTranscriptSummaryPane';
import "./AnalysisDetail.css";
import { useAppContext } from '../../../../../components/Main/AppContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={{ p: 1 }} // Padding of 1 (8px)
    >
      {value === index && (
        <Typography component="div">{children}</Typography>
      )}
    </Box>
  );
}

function AnalysisDetail({ companyEventId }) {
  const [tabValue, setTabValue] = useState(0);
  const { keTranscriptId, eventTitle } = useAppContext();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabs = [
    { label: "Metrics", id: "tab-0", ariaControls: "tabpanel-0" },
    { label: "Chat", id: "tab-1", ariaControls: "tabpanel-1" },
    ...(keTranscriptId ? [{ label: "Call Summary", id: "tab-2", ariaControls: "tabpanel-2" }] : []),
    // { label: "Call Details", id: "tab-3", ariaControls: "tabpanel-3" },
    { label: "My Notes", id: "tab-3", ariaControls: "tabpanel-3" }
  ];

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="analysis detail tabs"
        centered
        sx={{ minHeight: '35px', maxHeight: '35px'  }} // Smaller height for tabs
      >
        {tabs.map((tab, index) => (
          <Tab 
            key={tab.id}
            label={tab.label} 
            id={tab.id} 
            aria-controls={tab.ariaControls}
            sx={{ minHeight: '32px', fontSize: '0.85rem' }}
          />
        ))}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Box sx={{ overflow: 'auto' }}>
          <CompanyEventMetricList companyEventId={companyEventId} />
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        Chat Content for Event ID: {companyEventId}
      </TabPanel>
      
      {keTranscriptId && (
        <TabPanel value={tabValue} index={2}>
          <CompanyTranscriptSummaryPane 
            ke_transcript_id={keTranscriptId}
            selectedCallTitle={eventTitle}
            selectedTicker="TECK" // This should be dynamic, consider adding it to context
            selectedAISummary="Placeholder AI Summary" // This should be fetched or stored in context
          />
        </TabPanel>
      )}

      <TabPanel value={tabValue} index={keTranscriptId ? 3 : 2}>
        Call Details Content for Event ID: {companyEventId}
      </TabPanel>

      <TabPanel value={tabValue} index={keTranscriptId ? 4 : 3}>
        My Notes Content for Event ID: {companyEventId}
      </TabPanel>
    </Box>
  );
}

export default AnalysisDetail;
