import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import FlexDivider from "../../../Miscellaneous/FlexDivider";
import AnalysisDetail from './AnalysisDetail/AnalysisDetail';
import "./CompanyEventAnalysis.css";
import { useAppContext } from '../../../../components/Main/AppContext';

function CompanyEventAnalysis() {
    const [paneHeights, setPaneHeights] = useState([50, 50]); // Start with 2 equal sections
    const containerRef = useRef(null);
    const { companyEventId, eventTitle, eventType } = useAppContext();

    return (
        <Box className="company-event-analysis-container" ref={containerRef} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box>
                <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 0 }}>
                  Event: {eventTitle} {eventType}
                </h3>
          </Box>
          <Box>
            <AnalysisDetail companyEventId={companyEventId} />
          </Box>
        </Box>
    );
}

export default CompanyEventAnalysis;
